<template>
    <div>
      <article>
        <section>
          <div class="courseUnits_dialogs">
            <el-dialog
              :title="`Update A Post`"
              :visible.sync="isEditBlogModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
                <div v-loading="submitting">
                    <el-form
                    :model="blogForm"
                    :rules="rules"
                    label-position="top"
                    ref="blogForm"
                    class="demo-blogForm2"
                    >
                    <div class="row">
                        <div class="col-md-12">
                        <el-form-item
                            label="Title"
                            prop="blogTitle"
                        >
                            <el-input v-model="blogForm.blogTitle"></el-input>
                        </el-form-item>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                        <el-form-item
                            label="Description"
                            prop="description"
                        >
                            <el-input v-model="blogForm.description"></el-input>
                        </el-form-item>
                        </div>
                    </div> -->
                    <div class="row">
                      <div class="col-md-12">
                        <label class="mt-3 mb-2">Post Description</label><br />
                        <el-form-item
                          prop="description"
                          :disabled="isUploadingFile"
                        >
                          <editor
                            api-key="e5hm05oesjxxiuelp6g7fd8y2rncj6tqnuzkppcogbnf7ra3"
                            :init="{
                              height: 300,
                              menubar: false,
                              plugins: [
                                'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                              ],
                              toolbar:
                                'undo redo | casechange blocks | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlst checklist outdent indent | removeformat | a11ycheck code table help'
                            }"
                            placeholder="Type description here ..."
                            v-model="blogForm.description"
                          />
                        </el-form-item>
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                        <el-form-item label="Blog Image">
                            <el-upload
                            class="upload-demo"
                            drag
                            action="''"
                            :on-change="handleBlogPhotoPreview"
                            :on-remove="handleBlogPhotoRemove"
                            :file-list="blogPhotoFileLists"
                            :auto-upload="false"
                            :multiple="false"
                            >
                            <div v-if="!isUploadingFile">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                Drop file here or <em>click to upload</em>
                                </div>
                            </div>
                            <div v-else>
                                <el-progress
                                v-if="imageUploadingProgress != 100"
                                type="circle"
                                :color="progressColors"
                                :percentage="imageUploadingProgress"
                                :width="80"
                                :stroke-width="4"
                                ></el-progress>
                                <el-progress
                                v-else
                                type="circle"
                                :percentage="100"
                                status="success"
                                :width="80"
                                :stroke-width="4"
                                ></el-progress>
                                <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                                {{
                                imageUploadingProgress == 100
                                ? "Uploaded"
                                : "Uploading..."
                            }}
                                </div>
                            </div>
                            <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                            </el-upload>
                        </el-form-item>
                        </div>
                    </div>
    
                    <hr />
                    <el-form-item class="text-center">
                        <el-button
                        style="background-color: #083628; color: white;"
                        @click="editBlog('blogForm')"
                        >Add Post</el-button>
                    </el-form-item>
                    </el-form>
    
                    <div class="text-center">
                    <div class="text-center">
                        <small>© 2024 Acacia Wilderness Mweya, All rights reserved.</small>
                    </div>
                    <div class="text-center">
                        <!--v-model="blogForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                    </div>
                    </div>
                </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  import Editor from "@tinymce/tinymce-vue";
  export default {
    components: {
      editor: Editor,
    },
    data() {
      return {
        loading: false,
        isLoading: false,
        bookings: [],
        selectedBlogPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        
        blogPhotoFileLists: [
          {
            name: "food.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
          {
            name: "food2.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
        ],
  
        addUserModal: false,
        isEditBlogModalVisible: false,
        years: "",
        year_numbers: [],
        value: "",
        loadingError: false,
        submitting: false,
        course: "",
        blogForm: {
          blogTitle: "",
          description: "",
        },
  
        rules: {
            blogTitle: [
            {
              required: true,
              message: "Title is required",
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showBlogEditDialog: {
        required: true,
        type: Boolean,
      },
      blogData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showBlogEditDialog() {
        if (this.showBlogEditDialog === true) {
          this.isEditBlogModalVisible = true;
          this.blogForm.blogTitle = this.blogData.blogTitle;
          this.blogForm.description = this.blogData.description;
          this.selectedBlogPhotoFile = this.blogData.blogImage;
          this.blogPhotoFileLists = [
            {
              name: "blogImage",
              url: "this.blogData.blogImage",
            },
          ];
        } else {
          this.isEditBlogModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditBlogDialog");
      },
  
      handleBlogPhotoPreview(file) {
        console.log(file.raw);
        this.blogPhotoFileLists = [file];
        this.selectedBlogPhotoFile = file.raw;
      },
      handleUserPhotoPreview() {
        this.selectedBlogPhotoFile = null;
      },
  
      showAddUserModal() {
        this.addUserModal = true;
        this.getCountries();
      },
  
      async editBlog(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("blogTitle", this.blogForm.blogTitle);
              formData.append(
                "description",
                this.blogForm.description
              );
              formData.append("blogImage", this.selectedBlogPhotoFile);
              let response = await this.$http.patch(
                `blog/${this.blogData.blogID}`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
              if (
                response.data.success &&
                response.data.message == "BLOG_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Post updated successfully",
                  type: "success",
                });
                this.$emit("closeEditBlogDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Post",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>